<template>
    <el-form ref="form" :inline="true" :model="form" size="small" :rules="rules" label-position="top" v-loading="isLoading">
        <div class="admin_page_item mt18 pr0 pb0">
            <h1 class="title">银行基本户信息</h1>
            <div class="data_detail mt24">
                <el-form-item label="银行开户名" prop="corporateBankAccountName">
                    <el-input v-model="form.corporateBankAccountName" placeholder="请输入银行开户名"></el-input>
                </el-form-item>

                <el-form-item label="银行账号" prop="corporateBankNo">
                    <el-input v-model="form.corporateBankNo" placeholder="请输入银行账号"></el-input>
                </el-form-item>

                <el-form-item label="开户银行名称" prop="corporateBankBranchName">
                    <el-input v-model="form.corporateBankBranchName" placeholder="请输入开户银行名称"></el-input>
                </el-form-item>

                <el-form-item label="开户行行号" prop="corporateBankBranchNo">
                    <el-input v-model="form.corporateBankBranchNo" placeholder="请输入开户行行号"></el-input>
                </el-form-item>

                <el-form-item label="开户银行所在地" prop="corporateBankBranchProvince">
                    <el-cascader :options="regionData" v-model="corporateBankBranchProvince" @change="corporateBankBranchProvinceChange" placeholder="请选择开户银行所在地" :props="{ value: 'label' }"></el-cascader>
                </el-form-item>

                <el-form-item label="开户证明" class="full" prop="corporateAccountCertificate">
                    <div class="flexColumn">
                        <div class="uploadExample">
                            <el-upload
                                    class="avatar-uploader"
                                    :action="uploadURL"
                                    :before-upload="beforeUploadImage"
                                    :on-success="corporateAccountCertificateUpload"
                                    :show-file-list="false">
                                <img v-if="form.corporateAccountCertificate" :src="imageURL + form.corporateAccountCertificate" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <div class="example">
                                <el-image
                                        class="avatar" fit="contain"
                                        :src="require('../../../../assets/img/example/example_img04.png')"
                                        :preview-src-list="[require('../../../../assets/img/example/example_img04.png')]">
                                </el-image>
                                <div class="desc">示例图片</div>
                            </div>
                        </div>
                        <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M</div>
                        <div class="el-upload__tip">注：开户证明可上传开户许可证、开户申请、银行卡（正反面）</div>
                    </div>
                </el-form-item>
            </div>
        </div>

        <div class="admin_page_item mt18 pr0 pb0">
            <h1 class="title">结算账号信息</h1>
            <div class="data_detail mt24">
                <el-form-item label="银行开户名" prop="settleBankAccountName">
                    <el-input v-model="form.settleBankAccountName" placeholder="请输入银行开户名"></el-input>
                </el-form-item>

                <el-form-item label="银行账号" prop="settleBankNo">
                    <el-input v-model="form.settleBankNo" placeholder="请输入银行账号"></el-input>
                </el-form-item>

                <el-form-item label="开户银行名称" prop="settleBankBranchName">
                    <el-input v-model="form.settleBankBranchName" placeholder="请输入开户银行名称"></el-input>
                </el-form-item>

                <el-form-item label="开户行行号" prop="settleBankBranchNo">
                    <el-input v-model="form.settleBankBranchNo" placeholder="请输入开户行行号"></el-input>
                </el-form-item>

                <el-form-item label="开户银行所在地" prop="settleBankBranchProvince">
                    <el-cascader :options="regionData" v-model="settleBankBranchProvince" @change="settleBankBranchProvinceChange" placeholder="请选择开户银行所在地" :props="{ value: 'label' }"></el-cascader>
                </el-form-item>

                <el-form-item label="结算账号类型" prop="settleBankType">
                    <el-radio-group v-model="form.settleBankType">
                        <el-radio :label="1">借记卡</el-radio>
                        <el-radio :label="2">对公账户</el-radio>
                    </el-radio-group>
                </el-form-item>
            </div>
        </div>

        <div class="page_footer pb0" v-if="channelList.length > 0">
            <el-checkbox v-model="isChecked">
                <span style="color: #666">我已阅读并同意</span>
            </el-checkbox>
            <span class="checkboxText" v-for="(item, i) in channelList" :key="i" @click="getChannelAgreementInfo(item.channelCode, item.merchantNo)">{{Number(i) > 0 ? '，' : ''}}《{{item.channelName}}分账服务合作协议》</span>
        </div>

        <div class="page_footer">
            <el-button type="default" size="small" @click="$emit('changeStep', 0)">返 回</el-button>
            <el-button type="primary" size="small" @click="submitForm('form')">下一步，填写店铺经营信息</el-button>
        </div>

        <el-dialog :visible.sync="isPopup" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false" :destroy-on-close="true" top="5vh" class="popupRegister">
            <div class="protocolMain" v-html="agreementInfo" />
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="isPopup = false" size="small">关闭</el-button>
            </span>
        </el-dialog>
    </el-form>
</template>

<script>
  import {regionData} from 'element-china-area-data'
  import {imageURL, uploadURL} from "../../../../utils/config";
  import {beforeUploadImage} from "../../../../utils/method";
  import {getChannelAgreementInfo} from '../../../../api/admin/merchant'
  export default {
    name: "adminMerchantEditSecond",
    props: {
      infoData: {
        type: Object,
        default: () => {return {}}
      },
      channelList: {
        type: Array,
        default: () => {return []}
      }
    },
    data () {
      return {
        form: {
          corporateBankAccountName: '',
          corporateBankNo: '',
          corporateBankBranchName: '',
          corporateBankBranchNo: '',
          corporateBankBranchProvince: '',
          corporateBankBranchCity: '',
          corporateBankBranchArea: '',
          corporateAccountCertificate: '',
          settleBankAccountName: '',
          settleBankNo: '',
          settleBankBranchName: '',
          settleBankBranchNo: '',
          settleBankBranchProvince: '',
          settleBankBranchCity: '',
          settleBankBranchArea: '',
          settleBankType: 1,
        },
        rules: {
          corporateBankAccountName: [
            { required: true, message: '请输入银行开户名', trigger: 'blur' }
          ],
          corporateBankNo: [
            { required: true, message: '请输入银行账号', trigger: 'blur' },
            { pattern: '^(\\d{9,})$', message: '请输入正确的银行账号', trigger: 'blur'}
          ],
          corporateBankBranchName: [
            { required: true, message: '请输入开户银行支行名称', trigger: 'blur' }
          ],
          corporateBankBranchNo: [
            { required: true, message: '请输入开户行行号', trigger: 'blur' },
            { pattern: '^[0-9]+$', message: '请输入正确的开户行行号', trigger: 'blur'}
          ],
          corporateAccountCertificate: [
            { required: true, message: '请选择开户银行所在地', trigger: 'change' }
          ],
          corporateBankBranchProvince: [
            { required: true, message: '请上传开户证明', trigger: 'change' }
          ],
          settleBankAccountName: [
            { required: true, message: '请输入银行开户名', trigger: 'blur' }
          ],
          settleBankNo: [
            { required: true, message: '请输入银行账号', trigger: 'blur' },
            { pattern: '^(\\d{9,})$', message: '请输入正确的银行账号', trigger: 'blur'}
          ],
          settleBankBranchName: [
            { required: true, message: '请输入开户银行名称', trigger: 'blur' }
          ],
          settleBankBranchNo: [
            { required: true, message: '请输入开户行行号', trigger: 'blur' },
            { pattern: '^[0-9]+$', message: '请输入正确的开户行行号', trigger: 'blur'}
          ],
          settleBankBranchProvince: [
            { required: true, message: '请选择开户银行所在地', trigger: 'change' }
          ],
        },
        regionData: regionData,
        corporateBankBranchProvince: [],
        settleBankBranchProvince: [],
        imageURL: imageURL,
        uploadURL: uploadURL,
        beforeUploadImage: beforeUploadImage,
        isChecked: false,
        isPopup: false,
        agreementInfo: '',
        isLoading: false
      }
    },
    watch: {
      infoData () {
        this.form.corporateBankAccountName = this.infoData.corporateBankAccountName
        this.form.corporateBankNo = this.infoData.corporateBankNo
        this.form.corporateBankBranchName = this.infoData.corporateBankBranchName
        this.form.corporateBankBranchNo = this.infoData.corporateBankBranchNo
        this.form.corporateBankBranchProvince = this.infoData.corporateBankBranchProvince
        this.form.corporateBankBranchCity = this.infoData.corporateBankBranchCity
        this.form.corporateBankBranchArea = this.infoData.corporateBankBranchArea
        this.corporateBankBranchProvince = [this.infoData.corporateBankBranchProvince, this.infoData.corporateBankBranchCity, this.infoData.corporateBankBranchArea]
        this.form.corporateAccountCertificate = this.infoData.corporateAccountCertificate
        this.form.settleBankAccountName = this.infoData.settleBankAccountName
        this.form.settleBankNo = this.infoData.settleBankNo
        this.form.settleBankBranchName = this.infoData.settleBankBranchName
        this.form.settleBankBranchNo = this.infoData.settleBankBranchNo
        this.form.settleBankBranchProvince = this.infoData.settleBankBranchProvince
        this.form.settleBankBranchCity = this.infoData.settleBankBranchCity
        this.form.settleBankBranchArea = this.infoData.settleBankBranchArea
        this.settleBankBranchProvince = [this.infoData.settleBankBranchProvince, this.infoData.settleBankBranchCity, this.infoData.settleBankBranchArea]
        this.form.settleBankType = this.infoData.settleBankType
        this.isChecked = true
      }
    },
    methods: {
      corporateBankBranchProvinceChange (e) {
        this.form.corporateBankBranchProvince = e[0]
        this.form.corporateBankBranchCity = e[1]
        this.form.corporateBankBranchArea = e[2]
      },
      settleBankBranchProvinceChange (e) {
        this.form.settleBankBranchProvince = e[0]
        this.form.settleBankBranchCity = e[1]
        this.form.settleBankBranchArea = e[2]
      },
      corporateAccountCertificateUpload (res) {
        if (res.code === 0) {
          this.form.corporateAccountCertificate = res.data
        }
      },
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.channelList.length > 0 && !this.isChecked) {
              this.$message({
                message: '请阅读并同意分账服务合作协议',
                type: 'warning',
                showClose: true,
              });
              return false
            }
            this.$emit('submit', this.form)
          } else {
            return false;
          }
        });
      },
      getChannelAgreementInfo (channelCode, merchantNo) {
        this.isLoading = true
        getChannelAgreementInfo({
          channelCode: channelCode,
          merchantNo: merchantNo
        }).then(res => {
          this.isLoading = false
          if (res.code === 0) {
            this.agreementInfo = res.data
            this.isPopup = true
          }
        }).catch(() => {
          this.isLoading = false
        })
      }
    }
  }
</script>
